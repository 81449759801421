import React,  { Component } from "react"
import {graphql } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Img from "gatsby-image";
import Layout from "../components/layout"
import Cats from "../components/categoryList"
import Article from "../components/article"
import SEO from '../components/seo'
import Tags from "../components/tagList";




class SinglePost extends Component {
    render() {
        const post = this.props.data.wordpressPost


        return (

            <Layout>
                <SEO
                    title={post.yoast_meta.yoast_wpseo_title}
                    description={post.yoast_meta.yoast_wpseo_metadesc}
                    keywords={post.categories.map(category => category.name)}

                />


                <Article>
                    <Img
                        alt={post.featured_media.alt_text}
                        fluid={post.featured_media.localFile.childImageSharp.fluid}
                    />

                    <div className="metadata">
                        {post.formatted_date}:
                        {post.categories && (
                            <Cats categories={post.categories}  />
                        )}
                        {post.tags && (
                            <Tags tags={post.tags}  />
                        )}
                    </div>

                    <h2 className="title"
                        dangerouslySetInnerHTML={{__html: post.title}}

                    />



                    {parse(post.content)}

                </Article>
            </Layout>


        )
    }
}

SinglePost.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default SinglePost

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      date
      formatted_date
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
      name
      slug
      wordpress_id
    }
      tags {
          name
          slug
          wordpress_id
        }   
      featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`



